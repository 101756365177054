import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Chat from './components/Chat';
import NotFound from './components/NotFound';
import Orders from './components/Orders';
import KinguinResolutionCenter from './components/Kinguin-Resolution-Center';

function App() {

  useEffect(() => {
  }, []);

  return (
    <Router>
      <Routes>
	  
        <Route path="/order/:slug" element={<Chat />} />
        <Route path="/Kinguin-Resolution-Center" element={<KinguinResolutionCenter />} />
        <Route path="/orders/:slug" element={<Orders />} />
		
		
        {/* Fallback route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
